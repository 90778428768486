@use "variables" as v;

*,
::after,
::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  font-size: 18px;
}

body {
  width: 100%;
  height: 100vh;
  font-family: v.$font-stack;
  position: relative;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}

a,
button {
  cursor: pointer;
  font-size: 1rem;
}

h1 {
  color: v.$very-dark-blue;
  font-size: 2.325rem;
}
h2 {
  color: v.$very-dark-blue;
}
p {
  color: v.$grayish-blue;
}

ul {
  list-style-type: none;
  li {
    padding: 0;
    margin: 0;
  }
}

.container {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}

.nav-section {
  padding-top: 50px;
  margin-inline: 20px;
  &__logo {
    position: absolute;
    top: -80px;
    left: -10px;
    img {
      width: 150px;
    }
  }
  &__menu-list {
    width: 100%;
    li {
      text-align: center;
      padding: 20px 0;
      color: v.$white;
      font-size: 20px;

      &:first-child {
        border-top: 1px solid #9a9a9a;
      }
      &:last-child {
        border-bottom: none;
        a {
          display: inline-block;
          width: 100%;
          background-color: transparent !important;
          border: 3px solid v.$white;
        }
      }
    }
  }
  &__social-media {
    display: flex !important;
    justify-content: center;
    align-items: flex-end;
    margin: 180px 0 0 !important;
    li {
      a {
        color: v.$white;
        font-size: 30px;
      }
      &:last-child {
        a {
          background-color: transparent !important;
          padding: 0 !important;
          box-shadow: none !important;
        }
      }
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul {
      display: flex;
      gap: 40px;
      text-transform: uppercase;
      font-size: 16px;
      &:last-child {
        display: none;
      }
      li {
        a {
          transition: 1s;
          &:hover {
            color: v.$soft-red;
          }
        }
        &:last-child {
          a {
            background-color: v.$soft-red;
            color: v.$white;
            padding: 10px 25px;
            box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
            border-radius: 5px;
            transition: 1s;
            &:hover {
              background: v.$white;
              color: v.$soft-red;
              border: 2px solid v.$soft-red;
            }
          }
        }
      }
    }
  }
}

.header-section {
  position: relative;
  padding-top: 110px;

  &__container {
    width: 100%;
    max-width: 1104px;
    margin: 0 auto;
    padding: 0 20px;
    h1 {
      padding-block: 20px;
    }
    p {
      padding-bottom: 20px;
    }
  }
  & > div {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    & > div {
      p {
        line-height: 1.7;
      }
      div {
        display: flex;
        gap: 20px;
        a {
          padding: 15px 20px;
          border-radius: 5px;
          &:first-child {
            background-color: v.$soft-blue;
            color: v.$white;
            transition: 1s;
            &:hover {
              background: v.$white;
              color: v.$soft-blue;
              border: 3px solid v.$soft-blue;
            }
          }
          &:last-child {
            background-color: #f7f7f7;
            box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
            transition: 1s;
            &:hover {
              border: 3px solid v.$grayish-blue;
            }
          }
        }
      }
    }
  }
  &__illustration {
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
    div {
      position: absolute;
      z-index: -1;
      max-width: 40%;
      width: 100%;
      height: 300px;

      border-top-left-radius: 180px;
      border-bottom-left-radius: 180px;
      background-color: v.$soft-blue;
      top: 90px;
      right: 0;
    }
  }
}
.features-section {
  position: relative;
  margin-top: 90px;
  margin-bottom: 190px;

  &__shape {
    position: absolute;
    top: 400px;
    background-color: v.$soft-blue;
    width: 45%;
    height: 350px;
    border-top-right-radius: 180px;
    border-bottom-right-radius: 180px;
    z-index: -1;
  }
  &__container {
    width: 100%;
    max-width: 1104px;
    margin: 0 auto;
    padding: 0 20px;
    &--description {
      p {
        width: 50%;
        margin: 0 auto;
      }
    }
    div {
      &:first-child {
        text-align: left;
        width: 100%;
        margin: 0 auto;
        h2 {
          font-size: calc(1.125rem + 0.875vw);
          padding-bottom: 20px;
          text-align: center;
        }
        p {
          line-height: 1.7;
          padding-bottom: 50px;
        }
        .description {
          text-align: center;
        }
      }
      &:last-child {
        button {
          background-color: v.$soft-blue;
          color: v.$white;
          padding: 20px 40px;
          font-family: inherit;

          border-radius: 5px;
          transition: 0.2s;
          &:hover {
            background-color: v.$white;
            color: v.$soft-blue;
            border: 2px solid v.$soft-blue;
          }
        }
      }
    }
  }
  &__navigation {
    margin: 0 140px 70px 140px;
    ul {
      display: flex;
      justify-content: center;
      gap: 5px;
      align-items: center;
      text-align: center;
      border-bottom: 2px solid v.$grayish-blue;
      * {
        width: 250px;
        max-width: 100%;
        background: none;
        padding-bottom: 30px;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          color: v.$soft-red;
          border-bottom: 4px solid v.$soft-red;
        }
        h3 {
          font-family: v.$font-stack;
          font-weight: normal;
        }
      }
    }
  }

  &__offers {
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 50px;
    img {
      width: 100%;
    }
    div {
      position: relative;
      &:last-child {
        h3 {
          font-size: calc(0.875rem + 0.875vw);
          padding-bottom: 35px;
        }
        p {
          line-height: 1.7;
        }
        button {
          background: v.$soft-blue;
          color: v.$white;
          padding: 10px 30px;
        }
      }
    }
  }
}

.tabcontent {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  &__browser-extension-section {
    &--description {
      p {
        width: 50%;
        margin: 0 auto;
      }
    }
    &--container {
      width: 100%;
      max-width: 1104px;
      margin: 0 auto;
      font-size: inherit;
      padding: 0 20px;

      div {
        width: 100%;
        &:first-child {
          text-align: center;

          width: 100%;
          margin: 0 auto;
          // padding-inline: 30px;
          // padding-block-end: 35px;
          h2 {
            padding-bottom: 20px;
          }
          p {
            line-height: 1.7;
          }
        }
      }
    }
    &--browser {
      display: grid;
      text-align: center;
      grid-template-columns: 1fr 1fr;
      gap: 34px;
      position: relative;

      button {
        background-color: v.$soft-blue;
        color: v.$white;
        padding: 20px 40px;
        font-family: inherit;
        margin-top: 20px;
        border-radius: 5px;
        transition: 0.2s;
        &:hover {
          background-color: v.$white;
          color: v.$soft-blue;
          border: 2px solid v.$soft-blue;
        }
      }

      div {
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
          0 4px 6px -4px rgb(0 0 0 / 0.1);
        padding: 40px 5px 20px 5px;
        word-wrap: break-word;

        width: 100%;
        border-radius: 15px;
        img {
          &:first-child {
            margin-bottom: 20px;
          }
        }
        h4 {
          font-weight: bold;
        }
        &:nth-child(2) {
          position: relative;
          top: 40px;
        }
        &:nth-child(3) {
          position: relative;
          top: 80px;
        }
      }
    }
  }
}
.faq-section {
  position: relative;
  margin-top: 230px;
  margin-bottom: 100px;
  &__container {
    width: 100%;
    max-width: 1104px;
    margin: 0 auto;
    div {
      &:first-child {
        text-align: center;
        max-width: 525px;
        width: 100%;
        margin: 0 auto;

        h2 {
          font-size: calc(1rem + 0.875vw);
          padding-bottom: 20px;
        }
        p {
          line-height: 1.7;
        }
      }
      &:last-child {
        width: 100%;
        max-width: 625px;
        margin: 0 auto;
        div {
          display: grid;
          justify-content: center;
          text-align: justify;
          button {
            background: v.$soft-blue;
            color: v.$white;
            padding: 15px 20px;
            border-radius: 5px;
            font-weight: bold;
            margin-top: 50px;
            transition: 0.5s ease-in-out;
            &:hover {
              border: 2px solid v.$soft-blue;
              background-color: v.$white;
              color: v.$soft-blue;
            }
          }
        }
        ul {
          margin-top: 40px;
          li {
            border-top: 1px solid #d7d7d7;
            &:last-child {
              border-bottom: 1px solid #d7d7d7;
            }
            button {
              font-size: 0.92rem;
              text-align: left;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              padding: 18px 8px;
              cursor: pointer;
              border: none;
              background: none;
              font-family: v.$font-stack;
              &:hover {
                color: v.$soft-red;
              }
            }
          }
        }
      }
    }
  }
  &__answer_wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
    &--answer {
      text-align: left;
      padding: 20px 6px;
      color: v.$grayish-blue;
      line-height: 1.7;
      font-size: 0.925rem;
    }
  }
}

.accordion {
  list-style: none;
}

.control {
  font-size: 20px;
  .arrow {
    rotate: 180deg;
  }
}

/* activate toggle */
.accordion_item.active .button span {
  color: red;
}

.footer-contact-section {
  background-color: v.$soft-blue;
  padding: 50px 70px;
  color: v.$white;
  h2 {
    width: 50%;
    margin: 0 auto;
  }
  &__container {
    width: 100%;
    max-width: 1104px;
    margin: 0 auto;
    text-align: center;
    h2 {
      color: v.$white;
      padding: 0 50px;
    }
    p {
      font-weight: normal;
      padding-bottom: 30px;
      color: v.$white;
    }

    div {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 30px;
      input {
        width: 300px;
        height: 40px;
        padding-left: 15px;
        color: v.$grayish-blue;
      }
      button {
        background-color: v.$soft-red;
        color: v.$white;
        padding-inline: 20px;
        height: 40px;
        border-radius: 5px;

        &:hover {
          background-color: v.$white;
          border: 2px solid v.$soft-red;
          color: v.$soft-red;
        }
      }
    }
  }
}
.footer-links {
  background-color: v.$very-dark-blue;
  &__section {
    width: 100%;
    max-width: 1104px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    div {
      padding: 30px 10px;
      a {
        color: v.$grayish-blue;
        transition: 0.3s;
        &:hover {
          color: v.$soft-red;
        }
      }
      &:first-child {
        ul {
          display: flex;
          align-items: center;
          gap: 20px;
          img {
            width: 150px;
          }
        }
      }
      &:last-child {
        ul {
          display: flex;
          align-items: center;
          gap: 20px;
          li {
            a {
              font-size: 1.25rem;
              color: white;
              &:hover {
                color: v.$soft-red;
              }
            }
          }
        }
      }
    }
  }
}
//Mobile Size
@media (max-width: 635px) {
  .header-section {
    margin-top: -50px;
    &__illustration {
      div {
        top: 60px;
        padding-left: 70px;
        height: 200px;
        max-width: 100%;
        width: 70%;
      }
    }
    & > div {
      grid-template-columns: 1fr;
    }
    &__container {
      text-align: center;
      div {
        h1 {
          margin-top: 40px;
          font-size: 1.75rem;
        }
        &:nth-child(1) {
          display: grid;
          order: 1;
        }
        &:first-child {
          p {
            padding-block: 20px;
          }
          div {
            margin-top: 10px;
            gap: 5px;
            margin-inline: 10px;

            a {
              padding: 15px 15px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .tabcontent {
    grid-template-columns: 1fr;
    &__browser-extension-section {
      &--description {
        p {
          width: 100%;
        }
      }
      &--browser {
        grid-template-columns: 1fr;
      }
    }
  }
  .features-section {
    margin-bottom: 110px;
    &__shape {
      margin-top: 200px;
      height: 230px;
      width: 80%;
    }
    &__container {
      &--description {
        p {
          width: 100%;
          margin: 0 auto;
        }
      }
      div {
        &:first-child {
          h2 {
            margin-top: 120px;
          }
          p {
            margin-inline: 10px;
          }
        }
      }
    }
    &__offers {
      div {
        div {
          img {
            padding-inline: 20px;
          }
        }
        &:last-child {
          text-align: center;
          margin-top: 30px;

          p {
            padding-inline: 20px;
          }
        }
      }
    }
    &__navigation {
      margin: 0;
      ul {
        display: block;
        border-block: 1px solid v.$grayish-blue;
        margin-inline: 25px;
        margin-bottom: 100px;

        * {
          padding-top: 20px;
          width: 100%;
          button {
            color: v.$grayish-blue;
            &:hover {
              color: v.$soft-red;
              border-bottom: 4px solid v.$soft-red;
            }
          }
          &:nth-child(2) {
            border-block: 1px solid v.$grayish-blue;
          }
        }
      }
    }
  }
  .nav-section {
    padding: 20px 0;
    &__content {
      ul {
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          display: block;
        }
      }
    }
  }
  .footer-contact-section {
    h2 {
      width: 100%;
    }
    &__container {
      div {
        &:last-child {
          display: block;
          input {
            width: 100%;
          }
          button {
            margin-top: 20px;
            width: 100%;
          }
        }
      }
    }
  }
  .footer-links {
    &__section {
      div {
        &:first-child {
          ul {
            display: block;
          }
        }
        &:last-child {
          ul {
            display: block;
          }
        }
      }
    }
  }
  .faq-section {
    h2,
    p {
      margin-inline: 20px;
    }
    div {
      &:last-child {
        ul {
          margin-inline: 20px;
        }
      }
    }
  }

  .footer-contact-section {
    padding: 50px 20px;
    &__container {
      h2,
      h5 {
        padding: 10px 0;
      }
    }
  }

  .footer-links {
    div {
      ul {
        li {
          padding-bottom: 20px;
        }
      }
      &:last-child {
        padding: 0;
        & > ul {
          display: flex;
          justify-content: center;
        }
      }
    }
    &__section {
      text-align: center;
      display: block;
    }
  }
}
.browser-extension-section {
  &__browser {
    div {
      &:first-child {
        width: 85%;
      }
      margin: 0 auto;
      width: 85%;
    }
  }
}

//Tablet Size
@media (min-width: 768px) {
  body {
    overflow-x: hidden;
  }
}

@media (min-width: 1280px) {
  .tabcontent {
    &__browser-extension-section {
      &--browser {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
