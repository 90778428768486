@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");

//Default Color
$white: hsl(0, 0%, 100%);

//Primary Colors
$soft-blue: hsl(231, 69%, 60%);
$soft-red: hsl(0, 94%, 66%);

//Neutral
$grayish-blue: hsl(229, 8%, 60%);
$very-dark-blue: hsl(229, 31%, 21%);

$font-stack: "Rubik", sans-serif;
